    
	.snowing_effect_container {
	/*font_family*/font-family:Lucida Sans Unicode,Lucida Grande,sans-serif;
	font-size:11px;
	color: gray;
	line-height:16px;
	white-space:nowrap;
	margin:0;
	padding:0;
	display: none;
	min-width:100px;
}

span.snowing-button {display:block;width:16px;height:16px;padding: 0 0 0 20px;margin:0;background:url('https://googledrive.com/host/0B6W2C3whvuZdSWdabXZ5MnZ1REE/playpause.gif') no-repeat;cursor: pointer;}
span.snowing-play {background-position:0 -16px;}
span.snowing-pause {background-position:0 0;}



@media only screen and (max-width: 1200px) {
  .nav-responsive {
    font-size: 13px !important;
  }
  .nav-responsive a {
    padding: 10px 8px;
  }
}
@media only screen and (max-width: 1000px) {
  .nav-responsive {
    font-size: 11px !important;
  }
  .nav-responsive a {
    padding: 12px 6px !important;
  }
}
@media only screen and (max-width: 767px) {
  .nav-responsive {
    font-size: 18px !important;
  }
}

.testimonial-full-form {
    text-align: left;
}

.testimonial-form {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
}

.testimonial-form input:not(#verify):not(.submit) {
    width: 100% !important;
}

.btn {
    color: black;
}

#submit-testimonial {
    text-align: center;
}

.testimonials .row:not(.form-row) {
    margin-bottom: 30px;
    border-bottom: solid 1px #90AD02;
}

.clear-borders {
    margin: 0 !important;
    padding: 0 !important;
}

.logo-design {
    background-color: white;
    width: 96%;
    float: left;
    margin: 2%;
    border-radius: 2px;
    box-shadow: 0 0 10px -3px black;
}

// PORTFOLIO

#portfolio img {
    width: 100%;
	opacity: 1;
	cursor: pointer;
	//position: relative;
	//top: 0;
	
	transform: scale(1.1);
	
}

#portfolio img:hover {

}

#portfolio img:active {

}

#portfolio .portfolio-item {
	margin: 0;
	padding: 0;
	padding: 5px;
	
	border-radius: 50%;
	border: solid 8px #90AD02;
	
	overflow: hidden !important;
	
	box-shadow: 0 2px 7px -2px black;
	
	transition: border-color 0.3s;
	
	position: relative;
	
	cursor: pointer;
}

#portfolio .portfolio-item span.label {
    position: absolute;
    width: 100%;
    bottom: -100px; 
    left: 0; right: 0;
    z-index: 99;
    text-align: center;
    background-color: rgba(0,0,0,0.6);
    padding: 40px;
    font-weight: bold;
    box-shadow: 0 8px 20px 10px black;
    
    transition: bottom 0.2s;
}

#portfolio div.portfolio-item:hover span {
    bottom: 0px; 
    color: #78BF3D;
}

#portfolio div.portfolio-item:hover {
    //transform: scale(1.1)
    border-color: #4F7400;
}

#portfolio div.portfolio-item:hover img {
    opacity: 1;
}

#portfolio .portfolio-item {
    margin: 20px !important;
}

#portfolio .portfolio-item-container iframe.portfolio-item-full {
    position: fixed;
    top: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    z-index: 9999999999;
    //transform: scale(0);
    
    border:none;

}

#portfolio .portfolio-item:hover iframe.portfolio-item-full {

}

#portfolio div.portfolio-return {
    position: fixed;
    font-size: 50px;
    top: -70px;
    right: 0;
    margin: 10px;
    z-index: 999999999999;
    cursor: pointer;
    color: #90AD02; 
}

//////////////////////////////////

.price-table {

    text-align: center;
    max-width: 800px;
    width: 100%;
    overflow: hidden;
}

@media screen and (max-width: 450px) {
    .price-table {
        font-size: 10px;
    }
}

.price-table .main-title {
	background-color: #90AD02 !important;
	font-size: 25px;
	color: #4F7400 !important;
}

.price-table .price {
	font-size: 30px;
	font-weight: 700;
}

.price-table td:nth-child(2) {
	border-right: solid 1px rgba(256,256,256,0.05);
}

.price-table td:nth-child(1):not(.blank) {
	text-align: right;
}

.price-table td:nth-child(2), .price-table td:nth-child(3) {
	background-color: #4F7400;
	color: white;
	border-bottom: solid 1px rgba(256,256,256,0.05);
}


.price-table td {
	padding: 10px;
}

.fade-in {
	opacity: 0;
}

.print-port {
	height: 500px;
	overflow-y: scroll;
	background-color: #456E24;
	box-shadow: 0 0 10px -3px black;
	border: 1px solid #4F7400;
}

#photos {
   /* Prevent vertical gaps */
   line-height: 0;

   -webkit-column-count: 5;
   -webkit-column-gap:   0px;
   -moz-column-count:    5;
   -moz-column-gap:      0px;
   column-count:         5;
   column-gap:           0px;

}
#photos img {
  /* Just in case there are inline attributes */
  width: 100% !important;
  height: auto !important;
}

@media (max-width: 1200px) {
  #photos {
  -moz-column-count:    4;
  -webkit-column-count: 4;
  column-count:         4;
  }
}
@media (max-width: 1000px) {
  #photos {
  -moz-column-count:    3;
  -webkit-column-count: 3;
  column-count:         3;
  }
}
@media (max-width: 800px) {
  #photos {
  -moz-column-count:    2;
  -webkit-column-count: 2;
  column-count:         2;
  }
}
/*
@media (max-width: 400px) {
  #photos {
  -moz-column-count:    1;
  -webkit-column-count: 1;
  column-count:         1;
  }
}
*/

nav .nav li {
	z-index: 99999999 !important;
}

.call {
	width: 100%;
	max-width: 400px;
	margin: 0 auto;
	padding: 15px;
	margin-bottom: 5%;
	border-radius: 10px;
	border: darken(#90AD02, 15%) 1px solid;
	clear: both;
	color: white;
	font-size: 20px;
	display: block;
	text-align: center;
	box-shadow: 0 0 10px -3px black;
	background-color: #90AD02;
	background: linear-gradient(#90AD02, darken(#90AD02, 3%));
}

.call:hover {
	text-decoration: none;
	background-color: darken(#90AD02, 5%);
	background: linear-gradient(darken(#90AD02, 3%), #90AD02);
	color: white;
}

.slideshow-head {
	position: relative;
}

.slideshow-head img {
	width: 100%;
	position: absolute;
	opacity: 0;
}

.social-icon {
    width: 32px;
    margin: 3px;
}

.card-logos {
    clear: both;
    margin-top: 20px;
}

.card-logos img {
    float: right;
    width: 50px;
    padding: 2px;
}

.footer-bg h5 {
    color: white;
}

.center-margin {
	margin: 0 auto;
	text-align: center;
}

.home {
	margin-top: 40px;
}

.lime-header {
	text-align: center;
	background-image: url(../img/screens/repeat-bg.png);
	background-repeat: repeat-x;
	background-position: bottom;
}

.lime-header img {
	margin: 0 auto;
	position: relative;
	top: -30px;
}

@media screen and (max-width: 1150px) {
	nav {
		z-index: 999999 !important;
	}

	.lime-header img {
		top: 0;
	}
}

.top {
	position: relative;
	width:100%;
	background: @header;
	padding-top: 20px;
}
.divider {
	position: relative;
	width:100%;
	height:10px;
	background-color: @divider;
	-webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5), 0 0 40px rgba(0, 0, 0, 0.1) inset;
           -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5), 0 0 40px rgba(0, 0, 0, 0.1) inset;
            box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}
.divider:before,
.divider:after {
	content:"";
    position:absolute;
    z-index:-1;
    -webkit-box-shadow:0 0 20px rgba(0,0,0,0.8);
    -moz-box-shadow:0 0 20px rgba(0,0,0,0.8);
    box-shadow:0 0 20px rgba(0,0,0,0.8);
    top:50%;
    bottom:0;
    left:10px;
    right:10px;
    -moz-border-radius:100px / 10px;
    border-radius:100px / 10px;
}
.divider:after {
	right:10px;
    left:auto;
    -webkit-transform:skew(8deg) rotate(3deg);
       -moz-transform:skew(8deg) rotate(3deg);
        -ms-transform:skew(8deg) rotate(3deg);
         -o-transform:skew(8deg) rotate(3deg);
            transform:skew(8deg) rotate(3deg);
}
.logo {
	position: relative;
	padding-bottom: 0px;
}

.logo img {
    width: 100%;
	max-width: 300px;
	margin: 0 auto;
}

.logo-img {
	z-index: 99;
}
.logo-text {
	padding-top: 22px;
	position: absolute;
	top: 0;
	left: 15px;
}
.slogan h3 {
	color: @body-bg;
}
.main-content {
	margin-top: 15px;
}
h1 {
	margin-bottom:15px;
}
.middle-content {
	width: 100%;
	margin: 40px 0;
	background: @brand-primary;

	h2, a {
		color: @headings-alt;
	}
}
.bottom-content {
	margin-bottom: 40px;
}
.button-clear {
	width: 150px;
	height: 30px;
	border: 1px solid #fff;
	padding-top: 5px;
	margin: 0 auto 20px auto;
	border-radius: 10px;
}
.bullet-bg {
	background-color: @brand-primary;
	border-radius: 10px;
	padding:10px;

	li {
		margin-left:30px;
	}
	}
.footer-bg {
	width: 100%;
	min-height: 100px;
	background: @footer;
	color: @footer-text;
}

.footer-bg .container {
    background-image: url(../img/footer.jpg);
    background-color: #0F0F0F;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    padding: 10px;
}

.bottom-bar {
	width: 100%;
	height: 8px;
	background-color: @brand-primary;
}
.copyright {
	padding-top: 30px;
}
.lime {
	padding-top: 10px;
}
.top-img {
	padding-bottom: 20px;
}
.acceditation {
	img {
		padding-left: 20px;
		-webkit-filter: grayscale(100%);
		.transition(.5s);
	}
}
.acceditation {
	img:hover {
		-webkit-filter: grayscale(0%);
		.transition(.5s);
	}
}
.contact {
	text-align: center;
}

@media screen and (min-width: @screen-sm-min) {
	.contact {
		text-align: right;
	}
}

#area-map,
#area-map-small {
	margin-top: 30px;
}

.subtitle-pge {
	border-bottom: 1px solid #CBCBCB;
	border-top: 1px solid #CBCBCB;
	line-height: 1.4;
	margin-bottom: 25px;
	padding-bottom: 25px;
	margin-top: 25px;
	padding-top: 25px;
	font-weight: 200;
	text-align: center;
}

.get {
    display:none;
}
